*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Roboto", sans-serif !important;
}

html,
body,
#root {
  height: 100%;
  width: 100%;
}

@font-face {
  font-family: "SeaweedScript";
  src: local("SeaweedScript"),
    url(../assets/font/SeaweedScript-Regular.ttf) format("truetype");
}
.container-title{

  margin-bottom: 2rem;
  }
.container,
.home_container {
  max-width: 1440px;
  width: 95%;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-left: auto;
  margin-right: auto;
  @media (max-width:767px) {

  }
}

.home_container {
  max-width: 1200px;
}

// Custom scrollbar start
::-webkit-scrollbar {
  height: 10px;
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #fff;
}

::-webkit-scrollbar-thumb {
  background: #737373b3;
}

::-webkit-scrollbar-thumb:hover {
  background: #737373ff;
}
// Custom scrollbar end

// No scrollbar start
.no_scrollbar::-webkit-scrollbar {
  display: none;
}

.no_scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
// No scrollbar end

// No arrows start
.no_arrows::-webkit-outer-spin-button,
.no_arrows::-webkit-inner-spin-button {
  appearance: none;
  margin: 0;
}

.no_arrows[type="number"] {
  appearance: textfield;
}
// No arrows end

// antd styles start
.ant-form-item-has-error {
  .ant-upload {
    border-color: #ff4d4f !important;
  }
}

.row_not_expandable {
  .ant-table-row-expand-icon {
    visibility: hidden;
  }
}
// antd styles end
.ant-checkbox-wrapper{
  .ant-checkbox{
&:hover{

  background-color: #451c1a !important;
}
   
    
  }

}

.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-checked:not(.ant-checkbox-disabled) .ant-checkbox-inner {
  background-color:#EEBBB8 ;
}

.ant-btn-primary {
  // background-color: #EB817E!important;
  // color: #EB817E !important;     

  &:hover {
    border-color:rgba(195, 105, 101,0.3);
    color: #EEBBB8 !important;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
    // outline: .5px  #c36965;
    background-color: #ffffff!important;
  }
}
.ant-slider-handle::after {

  background-color: #7A7373!important;
  box-shadow: 0 0 0 2px #7A7373 !important;
 
}
.ant-slider-horizontal .ant-slider-track {
  height: 4px;
  background-color: #D9D9D9;
}

@media (min-width: 768px) {
  .container,
  .home_container {
    padding-left: .5rem;
    padding-right: .5rem;
  }
}

.ant-form-item-required {
  position: relative;
  padding-left: 10px;
  &::before {
    position: absolute;
    top: 4px;
    left: 0;
  }
}